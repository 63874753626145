// @flow

import React from 'react'
import classNames from 'classnames'
import ReactModal from 'react-modal'

import './CCModal.scss'

type Props = {
  children: any,
  title?: any,
  width?: number,
  customClasses?: string,
  headerButton?: any,
  headerIcon?: string,
  className?: string,
  isOpen: boolean,
  onRequestClose?: Function
}

export function CCModal ({
  children,
  title,
  width = 450,
  customClasses,
  headerButton,
  headerIcon,
  className,
  isOpen,
  onRequestClose
}: Props) {
  const classes = classNames('cc-modal__body', customClasses)

  return (<ReactModal
    overlayClassName="cc-modal-overlay"
    contentLabel={title}
    isOpen={isOpen}
    className={classNames('cc-modal', `cc-modal-${width}`, className)}
    shouldCloseOnOverlayClick={false}
    appElement={document.querySelector('.content-wrapper')}
    onRequestClose={onRequestClose}
  >
    {onRequestClose && (
      <div className="cc-modal__close" onClick={onRequestClose}>
        <i className="fa fa-times"/>
      </div>
    )}
    {title && <div className="cc-modal__header">
      {headerIcon && (
        <div className="cc-modal__header__icon">
          <i className={`fa fa-${headerIcon}`} />
        </div>
      )}
      <span className="cc-modal__header__title">{title}</span>
      {headerButton}
    </div>}
    <div className={classes}>
      {children}
    </div>
  </ReactModal>)
}
