// @flow

import React from 'react'

import { CCRoutes } from '../../../utils/routes'

import { CCUser } from '../CCUser/CCUser'

import { CCDropdownItem } from './CCDropdownItem'

type Props = {
  userName: string
}

export function CCNavbarUserProfile ({ userName }: Props) {
  return (
    <li className="navbar-item navbar-item-user">
      <a className="dropdown-toggle user-item" data-toggle="dropdown" href="#">
        <CCUser userName={userName} avatarOnly />
      </a>
      <ul className="dropdown-menu settings">
        <li className="user-list-item">
          <CCUser userName={userName} />
        </li>
        <li className="separator"/>
        <CCDropdownItem icon="fa-key" title="Change password" url={CCRoutes.editPasswordPath({ format: '' })}/>
        <CCDropdownItem icon="fa-sign-out" title="Sign out" url={CCRoutes.logoutPath({ format: '' })}/>
      </ul>
    </li>
  )
}
