// @flow

import React, { type Node } from 'react'

import './CCEmptyDataView.scss'
import { CCButton } from '../'

type Props = {
  description: Node,
  title: string,
  buttonTitle?: string,
  onButtonClick?: Function,
  footer?: any
}

export const CCEmptyDataView = (props: Props) => {
  return (
    <div className="empty-view-centered-flex" role="empty-state">
      <div className="cc-page-header__title empty-view-title">{props.title}</div>
      <span className="empty-view-description">
        {props.description}
      </span>
      {
        props.buttonTitle && props.onButtonClick
          ? (
          <CCButton
            onClick={props.onButtonClick}
            customClasses="create-btn"
          >{props.buttonTitle}</CCButton>
            )
          : null
      }
      <div className="empty-view-footer">
        {props.footer}
      </div>
    </div>
  )
}
