// @flow

import React, { useState } from 'react'

import { KnowledgeItem } from '../../../../../models'
import { useKnowledgeItems, useModal } from '../../../../../hooks'

import { CCButton, CCConfirmationModal, showErrorMessage, showInfoMessage } from '../../../../UI'

type Props = {
  row: {
    original: KnowledgeItem
  }
}

export function ActionsCell ({ row }: Props) {
  const [isDeleting, setIsDeleting] = useState(false)
  const { deleteKnowledgeItem, loadKnowledgeItems } = useKnowledgeItems()
  const [isDeleteModalOpen, , onDeleteRequestOpen, onDeleteRequestClose] = useModal()

  async function onDeleteConfirm () {
    setIsDeleting(true)
    if (await deleteKnowledgeItem(row.original.id)) {
      showInfoMessage('Q&A entry deleted')
      loadKnowledgeItems()
    } else {
      showErrorMessage('It was not possible to delete Q&A entry')
    }
    setIsDeleting(false)
  }

  return (
    <div className='k-table-additional-settings'>
      <CCButton
        onClick={onDeleteRequestOpen}
        disabled={isDeleting}
        tooltip="Delete entry"
      >
        <i className='fa fa-trash-o' />
      </CCButton>
      <CCButton onClick={() => null}>
        <i className='fa fa-eye' />
      </CCButton>
      {isDeleteModalOpen && (
        <CCConfirmationModal
          isShowing
          title="Delete confirmation"
          message={`Are you sure you want to delete "${row.original.question}" Q&A entry?`}
          confirmLabel="Delete"
          cancelLabel="Cancel"
          onConfirm={onDeleteConfirm}
          onCancel={onDeleteRequestClose}
        />
      )}
    </div>
  )
}
