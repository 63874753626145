// @flow

import moment from 'moment'

import React, { useEffect, useState } from 'react'

import { CCButton, CCDateRangePicker, CCDropdown, CCPageHeader, CCPagination, CCSpinnerPage } from '../../UI/'

import { SubscribersService } from '../../../services/subscribers/SubscribersService'
import { SegmentService } from '../../../services'
import { DateFilter } from '../../../utils/dateFilter'
import { CCRoutes } from '../../../utils/routes'
import { JobsList } from '../../Jobs/JobsList'
import styles from '../../UI/CCCustomizableTable/CCCustomizableTable.css'
import { useShop } from '../../../hooks'
import { SubscribersListRow } from './SubscribersListRow'

import '../../Dashboard/Dashboard.scss'
import './SubscribersList.scss'
import '../../WelcomeMessages/WelcomeMessageList/WelcomeMessageList.scss'

export default function SubscribersList () {
  const [shop] = useShop()
  const dateFilter: DateFilter = new DateFilter('subscribers:date-range')

  const [isLoading, setIsLoading] = useState(false)

  const [subscribers, setSubscribers] = useState([])
  const [pages, setPages] = useState(1)
  const [page, setPage] = useState(1)
  const [subscribersCount, setSubscribersCount] = useState(0)

  const [segments, setSegments] = useState([])
  const [query, setQuery] = useState('')
  const [segmentId, setSegmentId] = useState(null)
  const [startDate, setStartDate] = useState(dateFilter.loadDateRangeFromSession().startDate)
  const [endDate, setEndDate] = useState(dateFilter.loadDateRangeFromSession().endDate)

  const searchParams = (): Object => {
    return {
      page,
      query,
      segmentId,
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD')
    }
  }

  const fetchOrUpdate = async () => {
    setIsLoading(true)
    const subscribers = await SubscribersService.getSubscribers(shop, searchParams())
    setIsLoading(false)
    setSubscribers(subscribers.subscribers)
    setPages(subscribers.pages)
    setSubscribersCount(subscribers.count)
  }

  useEffect(() => {
    if (!shop.secure_id) return

    fetchOrUpdate()
  }, [page, query, segmentId, startDate, endDate, shop.secure_id])

  useEffect(() => {
    if (!shop.secure_id) return

    async function fetchData () {
      const segments = await SegmentService.getSegments(shop)
      setSegments(segments)

      const defaultSegment = segments.filter((segment) => segment.default === true)[0]
      setSegmentId(defaultSegment.id)
    }

    fetchData()
  }, [shop.secure_id])

  const handleDeleteClicked = (id: number) => {
    if (confirm('Are you sure?')) {
      setIsLoading(true)
      SubscribersService.deleteSubscriber(id)
      setIsLoading(false)
      setSubscribers(subscribers.filter((subscriber) => subscriber.id !== id))
    }
  }

  const handleSearch = (event: any) => {
    // $FlowFixMe
    const matchString = event.target.value?.toLowerCase()
    setPage(1)
    setQuery(matchString)
  }

  const handlePageClick = (data: any) => {
    setPage(data.selected + 1)
  }

  const handleSegmentChange = (event: any) => {
    setSegmentId(event.value)
  }

  const handleExportSubscribersClick = () => {
    if (confirm('Are you sure to export all subscribers?')) {
      window.location = CCRoutes.subscribersPath(shop, { format: 'csv', queryParams: searchParams() })
    }
  }

  const handleDateRangeChanged = ({ startDate, endDate }: { startDate: Date, endDate: Date }) => {
    dateFilter.saveDateRangeInSession({ startDate, endDate })
    setStartDate(startDate)
    setEndDate(endDate)
  }

  const renderList = () => {
    return <div className="container growth-tools-container">
      <table className="cc-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Subscribed At</th>
            <th>Source</th>
            <th>Subscription State</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{subscribers.map((subscriber) => (
          <SubscribersListRow
            key={subscriber.id}
            subscriber={subscriber}
            handleDeleteClicked={handleDeleteClicked}/>
        ))}</tbody>
      </table>
    </div>
  }

  return (
    <div className="growth-tools-list">
      <CCPageHeader
        customClasses="growth-tools-list__page-header"
        title="Users"
        description={
          `<span>Find all your chatbot and advisor users here.</span>
             <br/>
            <span>Check out our
              <a target="_blank"
                rel="noopener noreferrer"
                href="https://guide.chatchamp.com/Subscribers-c6534ef3a85d43f1a476fead23086b65">
                user guide
              </a>
              to learn more.
            </span>
          `
        }
      >
        <CCButton customClasses="create-btn" onClick={handleExportSubscribersClick} id="export-subscribers">
          Export Users
        </CCButton>
        <div className="spacer spacer--grow"/>
        <span className="header-label">
            Show users who subscribed
          </span>
        <CCDateRangePicker
          startDate={startDate}
          endDate={endDate}
          maxDate={new Date()}
          onChange={handleDateRangeChanged}
        />
      </CCPageHeader>

      <div className="container">
        <JobsList jobType="subscriber_export" title="Export of users"/>
      </div>

      <div className="container">
        <div className={styles.control_container}>
          <div className={styles.search_container} id="searchContainer">
            <input
              onChange={handleSearch} placeholder="Search..."
              className={styles.search_input} id="cc-search-input"/>
          </div>
          <div className={styles.selects_container} id="selectsContainer">
            <div className={styles.columns_select}>
              <CCDropdown
                display="block"
                theme="white"
                value={segmentId}
                options={segments.map((segment) => ({ value: segment.id, title: segment.name }))}
                onChange={handleSegmentChange}/>
            </div>
          </div>
        </div>
      </div>

      {(isLoading) ? (<CCSpinnerPage/>) : renderList()}

      <div className="container">
        <div className="footer-container">
          <div>
            <CCPagination pages={pages} onPageClick={handlePageClick}/>
          </div>
          <div>
            <span>{subscribersCount} {subscribersCount !== 1 ? 'users' : 'user'}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
