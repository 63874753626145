// @flow

import React from 'react'
import classnames from 'classnames'

import './CCLabel.scss'

type Props = {
  customClasses?: string,
  color?: 'success-green',
  text: string
}

export function CCLabel (props: Props) {
  const classes = classnames('cc-label', props.color, props.customClasses)

  return (
    <span className={classes}>
      {props.text}
    </span>
  )
}
