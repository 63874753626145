// @flow
import React, { type Node } from 'react'

// import { TrboIcon } from '../TrboIcon/TrboIcon'
// import { TrboTooltip } from '../TrboTooltip/TrboTooltip'

import './TrboPageHeader.scss'

export type Props = {
  title: string | Node,
  tooltip?: string | Node,
  articleURL?: string,
  description?: string | Node,
  children?: Node
}

export function TrboPageHeader ({ title, tooltip, articleURL, description, children }: Props) {
  return (
    <div className="trbo-page-header">
      <div className="trbo-page-header__content">
        <div className="trbo-page-header__title">
          <div className="trbo-page-header__title__content">
            {title}
          </div>
          {tooltip && articleURL && (
            <div className="trbo-page-header__icon">
              <a href={articleURL} target="_blank" rel="noreferrer">
                {/* <TrboTooltip
                  isHorizontal
                  position="right center"
                  customTrigger={<TrboIcon name="question-circle"/>}
                >{tooltip}</TrboTooltip> */}
              </a>
            </div>
          )}
          {/* {!tooltip && articleURL && (
            <div className="trbo-page-header__icon">
              <a href={articleURL} target="_blank" rel="noreferrer">
                <TrboIcon name="question-circle"/>
              </a>
            </div>
          )} */}
        </div>
        {description && (
          <div className="trbo-page-header__description">
            {description}
          </div>
        )}
      </div>
      {children && (
        <div className="trbo-page-header__children">
          {children}
        </div>
      )}
    </div>
  )
}
