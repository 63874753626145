// @flow

import React from 'react'

import { sanitizeNonBreaking, sanitizeWhiteSpace } from '../../../utils/sanitize'
import { CCButton, CCModal, CCTextInput } from '../../UI'

type Props = {
  onSubmit: any,
  isOpen: boolean,
  onRequestClose: Function
}

type State = {
  name: string,
  submitDisabled: boolean
}

export class CreateChatbotModal extends React.Component<Props, State> {
  state = {
    name: '',
    submitDisabled: false
  }

  handleTitleChange = (value: string) => {
    const name = sanitizeWhiteSpace(sanitizeNonBreaking(value))
    this.setState({ name })
  }

  handleSubmit = (e: any) => {
    const { name } = this.state
    const { onSubmit } = this.props

    e.preventDefault()
    this.setState({ submitDisabled: true })
    onSubmit(name)
  }

  render () {
    const { name, submitDisabled } = this.state
    const { isOpen, onRequestClose } = this.props

    return <CCModal title={'Create Chatbot'} width={500} isOpen={isOpen} onRequestClose={onRequestClose}>
      <form onSubmit={this.handleSubmit}>
        <div className="cc-modal__field">
          <div className="cc-modal__field__label">
            Chatbot name
          </div>
          <div className="cc-modal__field__body">
            <CCTextInput
              autoFocus
              value={name}
              errorMessage="Name is required"
              error={!name}
              onChange={this.handleTitleChange}/>
          </div>
        </div>
        <div className="cc-modal__footer">
          <CCButton onClick={onRequestClose}>
            Cancel
          </CCButton>

          <CCButton
            type="submit"
            disabled={!name || submitDisabled}
            color="primary"
            onClick={this.handleSubmit}>
            {submitDisabled ? 'Creating chatbot... ' : 'Submit'}
            {submitDisabled && <i className="fa fa-refresh fa-spin"/>}
          </CCButton>
        </div>
      </form>
    </CCModal>
  }
}
