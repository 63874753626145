// @flow

import React from 'react'
import classnames from 'classnames'

import './CCIconButton.scss'

type Props = {
  iconName: string,
  size?: 'mini' | 'normal',
  role?: string,
  dataTestid?: string,
  onClick?: Function
}

export function CCIconButton ({ iconName, size, role, dataTestid, onClick }: Props) {
  const buttonClasses = classnames('cc-icon-button', {
    'cc-icon-button--mini': size === 'mini'
  })

  return (
    <div
      className={buttonClasses}
      onClick={onClick}
      role={role}
      data-testid={dataTestid}
    >
      <i className={`fa fa-${iconName}`} />
    </div>
  )
}
