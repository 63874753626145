// @flow

import { type Node } from 'react'

type Props = {
  showOnStaging?: boolean,
  showOnProduction?: boolean,
  children: Node
}

function isOnProduction (): boolean {
  return window.location.hostname === 'app.chatchamp.com'
}

function isOnStaging (): boolean {
  return window.location.hostname === 'app-staging.chatchamp.com'
}

function isOnLocal (): boolean {
  return window.location.hostname === 'app.chatchamp.local'
}

export function FeatureFlag ({ showOnStaging, showOnProduction, children }: Props) {
  if (showOnStaging) {
    if (!isOnStaging() && !isOnLocal()) return null
  }

  if (showOnProduction) {
    if (!isOnProduction()) return null
  }

  return children
}
