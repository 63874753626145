// @flow

import React from 'react'
import ReactPaginate from 'react-paginate'

import { CCButton } from '../CCButton/CCButton'

type Props = {
  pages: number,
  onPageClick: Function,
  initialPage: number,
  marginPagesDisplayed?: number,
  pageRangeDisplayed?: number
}

export function TrboPagination ({
  pages,
  initialPage,
  onPageClick,
  marginPagesDisplayed,
  pageRangeDisplayed
}: Props) {
  const isFirstPage = initialPage === 0
  const isLastPage = initialPage === pages - 1

  return (
    <div className="trbo-pagination-container">
      {pages !== 0 && (
        <CCButton
          className="trbo-pagination-previous-button"
          onClick={() => onPageClick({ selected: initialPage - 1 })}
          disabled={isFirstPage}
          icon='arrow-left'
        >
          Previous
        </CCButton>
      )}
      <ReactPaginate
        breakLabel="..."
        breakClassName="break-me"
        pageCount={pages}
        initialPage={initialPage}
        marginPagesDisplayed={marginPagesDisplayed ?? 2}
        pageRangeDisplayed={pageRangeDisplayed ?? 5}
        onPageChange={onPageClick}
        containerClassName="pagination"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        activeClassName="active-link"
        previousClassName="trbo-paginate-prev"
        nextClassName="trbo-paginate-next"
      />
      {pages !== 0 && (
        <CCButton
          className="trbo-pagination-next-button"
          onClick={() => onPageClick({ selected: initialPage + 1 })}
          disabled={isLastPage}
          icon='arrow-right'
          iconRight
        >
          Next
        </CCButton>
      )}
    </div>
  )
}
