// @flow

import React, { useEffect } from 'react'

import { useKnowledgeItems, useShop } from '../../../../hooks'

import { JobsList } from '../../../Jobs/JobsList'
import { CCSpinnerPage, CCButtonGroupFilter, TrboPage, TrboPageHeader } from '../../../UI'

import { ImportButtonV2 } from './ImportButtonV2'
import { KnowledgeItemsEmptyListV2 } from './KnowledgeItemsEmptyListV2'
import { KnowledgeItemsTableV2 } from './KnowledgeItemsTableV2'
import { KnowledgeBaseListSearch } from './KnowledgeBaseListSearch'

// TODO; Thiago; 10.09.2024; Delete `KnowledgeBaseList` component and rename it to `KnowledgeBaseList` when it is ready to production.
export function KnowledgeBaseListV2 () {
  const [shop] = useShop()
  const {
    knowledgeItems,
    isLoadingList,
    pages,
    page,
    loadNextPage,
    loadKnowledgeItems
  } = useKnowledgeItems()
  const isShowingEmptyList = knowledgeItems.length === 0 && !isLoadingList

  function handlePageClick (data: { selected: number }): void {
    if ((data.selected + 1) !== page) loadNextPage(data.selected + 1)
  }

  function onCompleteJob (): void {
    if (shop.secure_id) loadKnowledgeItems()
  }

  function renderContent (): any {
    if (isLoadingList) return <CCSpinnerPage/>
    if (isShowingEmptyList) return <KnowledgeItemsEmptyListV2 />

    return (
      <KnowledgeItemsTableV2
        knowledgeItems={knowledgeItems}
        pages={pages}
        page={page}
        onPageClick={handlePageClick}
      />
    )
  }

  useEffect(() => {
    if (!shop.secure_id) return

    loadKnowledgeItems()
  }, [shop])

  const filterOptions = [
    { title: 'Q&A', icon: 'comments' },
    { title: 'Website (Coming soon)', icon: 'globe' },
    { title: 'File (Coming soon)', icon: 'file' },
    { title: 'Text (Coming soon)', icon: 'pencil-square-o' }
  ]

  return (
    <TrboPage header={
      <TrboPageHeader
        title="Knowledge Base"
        description='The knowledge base contains all relevant information for the customer service AI feature. The chatbot will only answer in the scope of the uploaded information.'
      >
        <ImportButtonV2 onComplete={loadKnowledgeItems} />
      </TrboPageHeader>
    }>
      <CCButtonGroupFilter
        filters={filterOptions}
        onSelect={() => null}
      />
      <KnowledgeBaseListSearch onSearch={() => {}} />
      <div className="hide">
        <JobsList
          jobType="import-knowledge-items"
          title="Importation of knowledge items"
          onJobComplete={onCompleteJob}
        />
      </div>
      {renderContent()}
    </TrboPage>
  )
}
