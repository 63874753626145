// @flow

import React, { useState } from 'react'

import { CCIconButton } from '../../../UI'

import './KnowledgeBaseListSearch.scss'

type Props = {
  onSearch: (query: string) => void
}

export function KnowledgeBaseListSearch ({ onSearch }: Props) {
  const [searchQuery, setSearchQuery] = useState('')

  function handleSearchChange (event) {
    const query = event.target.value
    setSearchQuery(query)
    onSearch(query)
  }

  return (
    <div className="kb__control_container">
      <div className="kb__search_container" id="searchContainer">
        <i className="fa fa-search"></i>
        <input
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search by question & answer"
          className="kb__search_input"
        />
      </div>
      <div className="kb__selects_container" id="selectsContainer">
        <CCIconButton iconName="ellipsis-h" />
      </div>
    </div>
  )
}
