// @flow

import React from 'react'
import { StoreProvider } from 'easy-peasy'

import { store } from '../../../../store'

import { FeatureFlag } from '../../../FeatureFlag/FeatureFlag'

import { KnowledgeBaseList } from './KnowledgeBaseList'
import { KnowledgeBaseListV2 } from './KnowledgeBaseListV2'

export default function KnowledgeBaseListPage () {
  return (
    <StoreProvider store={store}>
      <FeatureFlag showOnStaging>
        <KnowledgeBaseListV2 />
      </FeatureFlag>
      <FeatureFlag showOnProduction>
        <KnowledgeBaseList />
      </FeatureFlag>
    </StoreProvider>
  )
}
