/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import ahoy from 'ahoy.js'

ahoy.trackAll()

require('es6-shim')
const ReactRailsUJS = require('react_ujs')
import "@fontsource/inter/400.css"
import "@fontsource/inter/500.css"
import "@fontsource/inter/600.css"

// Inject platform css selectors
let os = 'other'
if (/Win/i.test(window.navigator.userAgent)) {
  os = 'windows'
} else if (/Mac/i.test(window.navigator.userAgent)) {
  os = 'mac'
}
document.getElementsByTagName('html')[0].classList.add(os)

// Support component names relative to this directory:
const componentRequireContext = require.context('components', true)

ReactRailsUJS.useContext(componentRequireContext)
