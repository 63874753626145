// @flow

import React from 'react'

import './CCRadioInput.scss'

type Props = {
  name: string,
  value: any,
  checked: boolean,
  label: string,
  onChange: Function
}

export function CCRadioInput ({ name, value, checked, label, onChange }: Props) {
  return (
    <div>
      <label className="cc-radio-input">
        <input
          type="radio"
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
        />
        {label}
      </label>
    </div>
  )
}
