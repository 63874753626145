// @flow

import React from 'react'

import { KnowledgeItem } from '../../../../models'

import { CCCustomizableTable, TrboPagination } from '../../../UI'

import { AVAILABLE_COLUMNS } from './Columns'

import '../../../../../assets/shared/settings.scss'
import './KnowledgeItemsTableV2.scss'

type Props = {
  knowledgeItems: Array<KnowledgeItem>,
  pages: number,
  page: number,
  onPageClick: (data: { selected: number }) => void
}

export function KnowledgeItemsTableV2 ({
  knowledgeItems,
  pages,
  page,
  onPageClick
}: Props) {
  const initialPageIndex = page - 1

  return (
    <div className="knowledge-table-container">
      <CCCustomizableTable
        showSearch={false}
        columns={AVAILABLE_COLUMNS}
        rows={knowledgeItems}
        tableStyle="rounded"
      />
      <TrboPagination
        pages={pages}
        initialPage={initialPageIndex}
        onPageClick={onPageClick}
      />
    </div>
  )
}
