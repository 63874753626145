// @flow

import * as React from 'react'
import { Row } from 'react-table'

import type { CCTableColumn } from './types/CCTableColumn'

import { CCTable } from './CCTable'

type Props = {
  data: Array<any>,
  columns: Array<CCTableColumn>,
  dropdownMenu?: Function,
  customRowClasses?: string,
  onRowDelete?: Function,
  rowDeletable?: boolean,
  onRowClick?: Function,
  controls?: React.Node,
  isLoading?: boolean,
  tableStyle?: 'default' | 'skinny' | 'rounded',
  summaryRow?: Object
}

export function CCTableWithSummary (props: Props) {
  if (props.summaryRow) {
    const customSort = (row: Row) => {
      if (row.original.id === 'summary') return -1
      return 0
    }
    const data = [...props.data]
    data.unshift(props.summaryRow)
    return (<CCTable {...props} customSort={customSort} data={data}/>)
  }
  return (<CCTable {...props}/>)
}
