// @flow

import React from 'react'

import { CCDropdown, CCDynamicInput, CCIconButton, CCTextInput, type DropdownOption } from '../../../UI'

type Props = {
  item: Object,
  onItemChange: Function,
  onItemDelete: Function,
  categoryOptions: Function
}

export function RoutineItem ({ item, onItemChange, onItemDelete, categoryOptions }: Props) {
  return (
    <div id={item.id} className="products-routine-item">
      <div className="products-routine-item__inputs">
        <div className="cc-form__field">
          <div className="cc-form__field__label">
            Title
          </div>
          <div className="cc-form__field__input">
            <CCTextInput
              placeholder={''}
              onBlur={value => onItemChange(item, 'title', value)}
              onPressEnterKey={value => onItemChange(item, 'title', value)}
              value={item.title}/>
          </div>
        </div>
        <div className="cc-form__field">
          <div className="cc-form__field__label">
            Description
          </div>
          <div className="cc-form__field__input">
            <CCDynamicInput
              className="multi-line-input"
              innerClass="multi-line-input-dashed"
              onBlur={value => onItemChange(item, 'description', value)}
              onPressEnterKey={value => onItemChange(item, 'description', value)}
              text={item.description}
              multiLine
            />
          </div>
        </div>
        <div className="cc-form__field">
          <div className="cc-form__field__label">
            Category
          </div>
          <div className="cc-form__field__input">
            <CCDropdown
              autoCompleteEnabled
              truncateAfter={10}
              options={categoryOptions()}
              value={item.category}
              flexible
              onChange={(option: DropdownOption) => onItemChange(item, 'category', option.value)}
            />
          </div>
        </div>
      </div>
      <div className="products-routine-item__remove-button">
        <CCIconButton
          iconName="trash"
          size="mini"
          onClick={() => onItemDelete(item)}
        />
      </div>
    </div>
  )
}
