// @flow

import React from 'react'
import { SortableElement, SortableHandle, Config } from 'react-sortable-hoc'
import type { ShowSetting } from '@chatchamp/chatchamp-types'

import { DEVICES, PATH_MATCHERS } from '../../../../../../models'
import { CCDropdown, CCIconButton, CCTextInput } from '../../../../../UI'

type Props = Config & {
  page: ShowSetting,
  pageIndex: Number,
  onChange: Function,
  onDelete: Function
}

const SortHandle = SortableHandle(() => <i className="fa fa-bars"/>)

export const VisibilityRow = SortableElement(({ page, pageIndex, onChange, onDelete }: Props) => {
  function hasValue (page: ShowSetting): boolean {
    return ['percentScrolled', 'percentScrolledHideAgain'].includes(page.show.type)
  }

  return (
    <div key={pageIndex} className="visibility-list__page">
      <div className="visibility-list__page__handle">
        <SortHandle/>
      </div>
      <div className="visibility-list__page__content">
        <div className="display-flex">
          <div className="width100">
            <h3 className="settings-subheader">
              Path
            </h3>
            <CCDropdown
              options={PATH_MATCHERS}
              onChange={(option) => onChange(pageIndex, 'pathMatcher', option.value)}
              value={page.pathMatcher ?? 'regex'}/>
          </div>
          <div className="settings-spacing-bottom">
            <h3 className="settings-subheader">
              Value
            </h3>
            <CCTextInput
              customClasses="settings-input"
              onChange={(val) => onChange(pageIndex, 'regex', val)}
              placeholder="Value"
              value={page.regex}
            />
          </div>
          <div className="settings-spacing-bottom">
            <h3 className="settings-subheader">
              Device
            </h3>
            <CCDropdown
              placeholder="When do you want the website chat to pop up?"
              options={DEVICES}
              onChange={(option) => onChange(pageIndex, 'device', option.value)}
              value={page.device}/>
          </div>
          <div className="settings-spacing-bottom">
            <h3 className="settings-subheader">
              Visibility
            </h3>
            <CCDropdown
              placeholder="When do you want the website chat to pop up?"
              options={[
                { value: 'immediately', label: 'immediately' },
                { value: 'hide', label: 'hide' },
                { value: 'percentScrolled', label: 'percentScrolled' },
                { value: 'percentScrolledHideAgain', label: 'percentScrolledHideAgain' },
                { value: 'timeOnPageInSeconds', label: 'timeOnPageInSeconds' }
              ]}
              onChange={(option) => onChange(pageIndex, 'show', { ...page.show, type: option.value })}
              value={page.show.type}/>
          </div>
          {(hasValue(page)) && <div className="settings-spacing-bottom">
            <h3 className="settings-subheader">
              Percentage
            </h3>
            <CCTextInput
              customClasses="settings-input"
              onChange={(val) => onChange(pageIndex, 'show', { ...page.show, value: val })}
              placeholder="Percentage (e.g. 30)"
              value={page.show.value}
            />
          </div>}
        </div>
      </div>
      <div className="visibility-list__page__delete">
        <CCIconButton
          iconName="trash"
          size="mini"
          role="delete-page"
          onClick={() => onDelete(pageIndex)}
        />
      </div>
    </div>
  )
})
