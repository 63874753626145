// react-select theme
export default {
  placeholder: (base) => ({
    ...base,
    width: '80%',
    fontWeight: 600,
    color: '#101828',
    textAlign: 'left',
    marginLeft: 0,
    marginRight: 0
  }),
  control: (base, state) => ({
    ...base,
    background: 'white',
    border: state.isFocused ? '1px solid #9DB8BD' : '1px solid #DAE4E6',
    borderRadius: 6,
    height: 'auto',
    minHeight: 40,
    maxHeight: 150,
    overflow: 'auto',
    boxShadow: state.isFocused ? '0 2px 6px 1px rgba(0, 0, 0, 0.01)' : 'none',
    '&:hover': {
      boxShadow: state.isFocused ? '0 2px 6px 1px rgba(0, 0, 0, 0.01)' : 'none'
    },
    borderBottomLeftRadius: state.selectProps.menuIsOpen ? 0 : 6,
    borderBottomRightRadius: state.selectProps.menuIsOpen ? 0 : 6,
    padding: state.selectProps.isMulti ? '0 8px' : '0 0 0 8px'
  }),
  indicatorSeparator: (base) => ({
    ...base,
    background: '#DAE4E6'
  }),
  indicatorsContainer: (base, state) => ({
    ...base,
    display: state.selectProps.isMulti ? 'none' : 'inherit'
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: '#101828',
    '&:hover': {
      color: '#101828'
    }
  }),
  singleValue: (base, state) => ({
    ...base,
    fontWeight: 600,
    color: state.isDisabled ? '#acc7cb' : '#101828',
    textAlign: 'center'
  }),
  option: (base, state) => ({
    ...base,
    fontWeight: 600,
    padding: 12,
    color: state.isSelected ? '#101828' : '#101828',
    background: state.isFocused ? '#f8f8f8' : 'none',
    '&:not(:last-child)': {
      borderBottom: '1px solid #DAE4E6'
    },
    '&:active': {
      background: '#f3f3f3'
    }
  }),
  menuList: (base) => ({
    ...base,
    marginTop: '-1px'
  }),
  menu: (base) => ({
    ...base,
    marginTop: '-1px',
    borderRadius: 0,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    border: '1px solid #9DB8BD',
    borderTop: 'none',
    boxShadow: '0 2px 6px 1px rgba(0, 0, 0, 0.01)'
  }),
  groupHeading: (base) => ({
    ...base,
    background: '#fafafa',
    color: '#101828',
    fontWeight: '700',
    letterSpacing: '1px',
    fontSize: 10,
    padding: 8,
    paddingHorizontal: 12,
    borderTop: '1px solid #DAE4E6',
    borderBottom: '1px solid #DAE4E6',
    marginTop: -13
  }),

  multiValue: (base) => ({
    ...base,
    background: '#F9FAFB',
    color: '#344054',
    fontWeight: 700,
    borderRadius: 6,
    margin: '4px',
    padding: '6px 8px'
  }),

  multiValueLabel: (base) => ({
    ...base,
    borderRadius: 6,
    background: '#F9FAFB',
    color: '#344054',
    fontWeight: 700,
    fontSize: 14
  }),

  multiValueRemove: (base) => ({
    ...base,
    cursor: 'pointer',
    color: '#98A2B3',
    ':hover': {
      backgroundColor: 'transparent',
      color: '#101828'
    }
  })
}
