// @flow

import { useEffect } from 'react'

export function useTooltip () {
  useEffect(() => {
    // $FlowFixMe
    $('[data-toggle="tooltip"]').tooltip({
      container: 'body'
    })
  }, [])
}
