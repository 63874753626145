// @flow

import React from 'react'

import { CCEmptyDataView } from '../../../UI'

import EmptyStateImg from './Icon.png'

import './KnowledgeItemsEmptyListV2.scss'

export function KnowledgeItemsEmptyListV2 () {
  return (
    <div className="kb__empty-state">
      <img src={EmptyStateImg} alt="empty-state" height="151px" width="208px" />
      <CCEmptyDataView
        title="No data source added"
        description={<>There are no items in the library, press <strong>“New data source”</strong> to train AI bot</>}
      />
    </div>
  )
}
