// @flow

import React, { useCallback, useMemo, useState } from 'react'
import _ from 'lodash'
import toastr from 'toastr'

import { isEmail, isPhoneNumber } from '../../../models/Attribute'
import { AttributesService, ButtonService, MessageService } from '../../../services'
import type { Attribute, Message } from '../../../models'
import { useShop } from '../../../hooks'

import { CCDropdown, type DropdownOption, CCEditableDropdown, CCTextInput, CCCheckbox } from '../../UI'

import { validationOptions, validationTypeForSubscriberAttribute, validationTypes } from './validation'

import './InputElement.scss'

const OMITTED_MESSAGE_PROPERTIES = ['text', 'step']

// an input message has a button to associate the subscriber attribute; Dominik; 30.07.2019; :(
// therefore it has buttons, while we're actually only really interested in the first button

type Props = {
  tags: Array<Attribute>,
  buttons: Array<Object>,
  message: Message,
  onTagCreate: Function
}

const buildAttributeOptions = (attributes: Array<Attribute>): Array<DropdownOption> => {
  return attributes.map((tag) => ({
    value: (+tag.id).toString(),
    label: tag.name
  }))
}

export function InputElement (props: Props) {
  const [selectedAttributeId, setSelectedAttributeId] = useState(props.buttons.length && +props.buttons[0].tag_id)
  const [attributeOptions, setAttributeOptions] = useState(buildAttributeOptions(props.tags))
  const [message, setMessage] = useState(props.message)
  const [shop] = useShop()

  const selectedAttribute = useMemo(() => {
    return _.find(attributeOptions, { value: selectedAttributeId.toString() }) || {
      value: '',
      label: ''
    }
  }, [selectedAttributeId, attributeOptions])

  const updateMessage = useCallback(async (newMessage): Promise<any> => {
    try {
      await MessageService.updateMessage(newMessage.id, _.omit(newMessage, OMITTED_MESSAGE_PROPERTIES))
      toastr.remove()
      toastr.success('Message updated.')
    } catch (err) {
      toastr.error(`Message update failed. (${err.message})`)
    }
  }, [])

  const updateButton = useCallback((attributeId): Promise<any> => {
    return ButtonService.updateButton(props.buttons[0].id, { tag_id: attributeId.toString() })
  }, [props.buttons])

  const tagChanged = useCallback((tagOption: DropdownOption) => {
    setSelectedAttributeId(parseInt(tagOption.value))
    const newMessage = {
      ...message,
      validation_type: validationTypeForSubscriberAttribute(tagOption.label)
    }
    setMessage(newMessage)
    updateMessage(newMessage)
    updateButton(parseInt(tagOption.value))
  }, [message, updateButton, updateMessage])

  const handleTagChange = useCallback(async (tagOption: DropdownOption) => {
    if (!tagOption.__isNew__) return tagChanged(tagOption)

    const { onTagCreate } = props

    const newTag = await AttributesService.createAttribute(shop, { name: tagOption.label }).then((attribute) => attribute)

    await onTagCreate(newTag)
    const savedTag = { value: newTag.id.toString(), label: newTag.name }
    attributeOptions.push(savedTag)
    setAttributeOptions(attributeOptions)
    tagChanged(savedTag)
  }, [tagChanged, props, shop, attributeOptions])

  const handlePropertyChange = useCallback((propertyPath: string) => {
    return (value: any) => {
      const prevValue = _.get(message, propertyPath)
      if (value === prevValue) return

      const newMessage = Object.assign({}, message)
      _.set(newMessage, propertyPath, value)
      setMessage(newMessage)
      updateMessage(newMessage)
    }
  }, [message, updateMessage])

  const shouldShowValidation = useCallback(() => {
    return message.validation_type !== validationTypes.TEXT
  }, [message])

  const shouldDisableValidationDropdown = useCallback(() => {
    return (
      isEmail(selectedAttribute.label) &&
      message.validation_type === validationTypes.EMAIL
    ) || (
      isPhoneNumber(selectedAttribute.label) &&
        message.validation_type === validationTypes.PHONE_NUMBER
    )
  }, [selectedAttribute.label, message.validation_type])

  return (
    <div className="input-options">
      <div className="input-options__field">
        <span className="input-options__field-label">
          Save response as subscriber attribute
        </span>
        <CCEditableDropdown
          value={selectedAttribute}
          options={attributeOptions}
          onChange={handleTagChange}
        />
      </div>

      <div className="input-options__field">
        <span className="input-options__field-label">
          Validate response as
        </span>
        <CCDropdown
          mini
          disabled={shouldDisableValidationDropdown()}
          options={validationOptions}
          value={message.validation_type}
          onChange={(option) => handlePropertyChange('validation_type')(option.value)}
        />
      </div>

      {shouldShowValidation() && <div className="input-options__validation" role='validation-container'>
        {message.validation_type === validationTypes.REGEX &&
          <div className="input-options__field-block">
            <div className="input-options__field-label-block">
              Regular Expression
            </div>
            <div className="input-options__validation-field validation__expression">
              <CCTextInput
                placeholder="/[A-Z]+/g"
                onBlur={handlePropertyChange('validation_expression')}
                onPressEnterKey={handlePropertyChange('validation_expression')}
                value={message.validation_expression} />
            </div>
          </div>
        }

        <div className="input-options__field margin-top-10">
          <div className="input-options__field-label-block">
            <CCCheckbox
              type="checkbox"
              checked={message.data.resendInputMessage}
              className="checkbox"
              label="Send input message again if validation fails"
              onChange={(e: any) => handlePropertyChange('data.resendInputMessage')(e.target.checked)}
            />
          </div>
        </div>

        <div className="input-options__field-block">
          <div className="input-options__field-label-block">
            <span>Message when subscriber responds with an invalid answer</span>
            <i className="input-options__icon fa fa-info-circle" />
            <span className="input-options__tooltiptext">
              This message will be sent if the subscriber responds with a message which is invalid
              (e.g. the response is not a valid email, phone number ...)
            </span>
          </div>
          <div className="input-options__validation-field">
            <CCTextInput
              placeholder="Enter message"
              onBlur={handlePropertyChange('validation_message')}
              onPressEnterKey={handlePropertyChange('validation_message')}
              value={message.validation_message} />
          </div>
        </div>
      </div>}

      <div className="input-options__field-block margin-top-10">
        <div className="input-options__field-label-block">
          Placeholder
        </div>
        <div className="input-options__validation-field">
          <CCTextInput
            placeholder="Enter message"
            onBlur={handlePropertyChange('data.placeholder')}
            onPressEnterKey={handlePropertyChange('data.placeholder')}
            value={message.data.placeholder} />
        </div>
      </div>

      <div className="input-options__field-block margin-top-10">
        <div className="input-options__field-label-block">
          Button title
        </div>
        <div className="input-options__validation-field">
          <CCTextInput
            placeholder="Enter message"
            onBlur={handlePropertyChange('data.input.buttonTitle')}
            onPressEnterKey={handlePropertyChange('data.input.buttonTitle')}
            value={message.data.input?.buttonTitle || 'Senden'} />
        </div>
      </div>
    </div>
  )
}
