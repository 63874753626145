// @flow

import React from 'react'

import { Shop } from '../../../../../models'

import { CCTextInput } from '../../../../UI'

import { GenericPanel } from '../generic/GenericPanel'

type Props = {
  shop: Shop,
  onChange: Function
}

export function CustomDataLayer ({ shop, onChange }: Props) {
  return (
    <>
      <h3 className="settings-subheader">Custom Data Layer variable</h3>
      <p className="settings-text">Please enter the name of your global data layer variable, so that Chatchamp can access it via Javascript</p>
      <CCTextInput
        customClasses="settings-input"
        onChange={(val) => onChange('chat_bubble.tracking.customDataLayerConfig.variableName', val)}
        placeholder="Enter your custom data layer variable"
        value={shop.chat_bubble.tracking.customDataLayerConfig.variableName}
      />
      <GenericPanel
        customChatbotPanelTitle="Chatbot Events"
        customWizardPanelTitle="Advisor Events"
        trackerPropertyPath="chat_bubble.tracking.customDataLayerConfig"
        shop={shop}
        onChange={onChange}
      />
    </>
  )
}
