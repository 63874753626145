// @flow

import React from 'react'

import { Message } from '../../../../models'

import { CCCheckbox, CCFormField, CCTextInput } from '../../../UI'

type Props = {
  message: Message,
  handlePropertyChange: Function
}

export function AuthTab ({ message, handlePropertyChange }: Props) {
  return (
    <>
      <CCFormField>
        <CCCheckbox
          style={{ display: 'inline-block', marginRight: '5px' }}
          type="checkbox"
          checked={message.data.basicAuthEnabled || false}
          className="checkbox"
          label="Basic auth"
          onChange={(e: any) => handlePropertyChange('data.basicAuthEnabled', false)(e.target.checked)}
        />
      </CCFormField>
      {message.data.basicAuthEnabled && (
        <div className="cc-form__field">
          <CCFormField>
            <CCTextInput
              placeholder="User"
              onBlur={handlePropertyChange('data.basicAuthUsername', false)}
              onPressEnterKey={handlePropertyChange('data.basicAuthUsername', false)}
              value={message.data.basicAuthUsername}
            />
          </CCFormField>
          <CCFormField>
            <CCTextInput
              placeholder="Password"
              onBlur={handlePropertyChange('data.basicAuthPassword', false)}
              onPressEnterKey={handlePropertyChange('data.basicAuthPassword', false)}
              value={message.data.basicAuthPassword}
            />
          </CCFormField>
        </div>
      )}
      <CCFormField label="Bearer token">
        <CCTextInput
          onBlur={handlePropertyChange('data.webhook.bearerToken', false)}
          onPressEnterKey={handlePropertyChange('data.webhook.bearerToken', false)}
          value={message.data.webhook?.bearerToken}
        />
      </CCFormField>
    </>
  )
}
