// @flow
import React from 'react'
import classNames from 'classnames'
import { RuleOperatorType, ruleOperators } from '@chatchamp/chatchamp-types'
import { CCDropdown, CCIconButton } from '../../../UI'
import { ArrowIf } from './Icons/ArrowIf'

const variants = {
  IF: 'if',
  ELSEIF: 'elseif'
}

type OnChangeOperatorType = (operator: RuleOperatorType) => void

type RuleHeaderProps = {
  variant: $Values<typeof variants>,
  operator: RuleOperatorType,
  onChangeOperator: OnChangeOperatorType,
  onDelete: Function
}

type RuleOperatorProps = {
  operator: RuleOperatorType,
  onChange: OnChangeOperatorType
}

function RuleOperator (props: RuleOperatorProps) {
  const operators = [{ value: ruleOperators.ALL, label: 'ALL' }, { value: ruleOperators.ANY, label: 'ANY' }]

  function handleChangeOperator (option) {
    props.onChange(option.value)
  }

  return <CCDropdown
    role='rule-operator'
    customClasses='rule-router-element-row__rule-operator'
    value={props.operator}
    options={operators}
    onChange={handleChangeOperator}
  />
}

export function RuleHeader (props: RuleHeaderProps) {
  const statements = {
    [variants.IF]: <b>IF</b>,
    [variants.ELSEIF]: <b>ELSE IF</b>
  }

  return <div className='rule-router-element-row__header' role='rule-header'>
    <div className='rule-router-element-row__header__text'>
      <ArrowIf />
      <span className='rule-router-element-row__header__name'>
        {statements[props.variant] || statements[variants.IF]}
        <RuleOperator operator={props.operator} onChange={props.onChangeOperator} />
        of the following match
      </span>
    </div>
    <div className={classNames('rule-router-element-row__header__delete', 'rule-router-element-row__delete')}>
      <CCIconButton
        iconName="trash"
        size="mini"
        role="rule-delete"
        onClick={props.onDelete}
      />
    </div>
  </div>
}
