// @flow

import React, { type Node, useState } from 'react'

import { CCModal } from '../CCModal/CCModal'
import { CCButton } from '../CCButton/CCButton'

import './CCConfirmationModal.scss'

type Props = {
  isShowing?: boolean,
  title?: string,
  message?: string | Node,
  confirmLabel?: string,
  cancelLabel?: string,
  onConfirm: Function,
  onCancel?: Function,
  children?: Node
}

export function CCConfirmationModal ({
  isShowing = false,
  title = 'Confirmation',
  message = 'Are you sure?',
  confirmLabel = 'Yes',
  cancelLabel = 'No',
  onConfirm,
  onCancel,
  children
}: Props) {
  const [isShowingModal, setIsShowingModal] = useState(isShowing)

  function handleClick (): void {
    setIsShowingModal(true)
  }

  function handleConfirm (): void {
    setIsShowingModal(false)
    onConfirm()
  }

  function handleCancel (): void {
    setIsShowingModal(false)
    onCancel?.()
  }

  return (
    <>
      <span onClick={handleClick}>{children}</span>
      {isShowingModal && (
        <CCModal
          isOpen
          title={title}
          headerIcon="warning"
          className="cc-confirmation-modal"
          width={491}
        >
          <div className="cc-confirmation-modal-content">
            <div className="cc-confirmation-modal-content__message" role="confirmation-message">
              {message}
            </div>
            <div className="cc-confirmation-modal-content__actions">
              <CCButton onClick={handleCancel}>{cancelLabel}</CCButton>
              <CCButton color="red" onClick={handleConfirm}>{confirmLabel}</CCButton>
            </div>
          </div>
        </CCModal>
      )}
    </>
  )
}
