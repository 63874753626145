// @flow

import { useStoreActions, useStoreState } from 'easy-peasy'

import { showErrorMessages } from '../components/UI'

import { useShop } from './useShop'

export function useKnowledgeItems () {
  const [shop] = useShop()
  const state = useStoreState(state => state.knowledgeItems)
  const actions = useStoreActions(actions => actions.knowledgeItems)

  function buildQueryParams (page: number = 1): Object {
    return { queryParams: { page } }
  }

  async function loadNextPage (page: number): Promise<void> {
    try {
      await actions.fetch({ shop, params: buildQueryParams(page) })
    } catch (error) {
      showErrorMessages(error)
    }
  }

  async function loadKnowledgeItems (): Promise<void> {
    try {
      await actions.fetch({ shop, params: buildQueryParams() })
    } catch (error) {
      showErrorMessages(error)
    }
  }

  async function importFile (file: File): Promise<void> {
    try {
      await actions.importFile({ shop, file })
    } catch (error) {
      showErrorMessages(error)
    }
  }

  async function loadUploadStatus (): Promise<void> {
    try {
      await actions.loadUploadStatus({ shop })
    } catch (error) {
      showErrorMessages(error)
    }
  }

  async function deleteKnowledgeItem (id: number): Promise<boolean> {
    try {
      // TODO; Implement it
      // await actions.deleteKnowledgeItem({ id, shop })
      await new Promise((resolve) => setTimeout(resolve, 500))

      return true
    } catch (error) {
      showErrorMessages(error)
    }

    return false
  }

  return { ...state, loadNextPage, loadKnowledgeItems, importFile, loadUploadStatus, deleteKnowledgeItem }
}
