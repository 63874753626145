// @flow

import React, { type Node } from 'react'

import './TrboPage.scss'

export type Props = {
  header?: Node,
  children?: Node,
  className?: string
}

export function TrboPage ({ header, children, className }: Props) {
  return (
    <div className={`trbo-page ${className || ''}`}>
      {header && (
        <div className="trbo-page__header">
          <div className="trbo-page__header__wrapper">
            {header}
          </div>
        </div>
      )}
      <div className="trbo-page__content">
        <div className="trbo-page__content__wrapper">
          {children}
        </div>
      </div>
    </div>
  )
}
