// @flow

import React from 'react'
import { Provider, Subscribe } from 'react-contextual'
import { StoreProvider } from 'easy-peasy'

import { ChatbotService } from '../../services'

import { createBotBuilderStore } from '../../storeModels/botBuilderStore'
import { CCButton, CCDynamicInput } from '../UI'

import { showErrorMessages } from '../../utils/feedbackMessages'
import { store } from '../../store'

import { BotBuilder } from './BotBuilder'

import './ChatbotBuilder.scss'

type Props = {
  blocks: Array<Object>,
  campaign: Object,
  campaigns: Array<Object>,
  facebookPage: Object,
  locale: string,
  messages: Array<Object>,
  selectedBlock: Object,
  tags: Array<Object>,
  isAdmin: Boolean
}

export default function ChatbotBuilder (props: Props) {
  // $FlowFixMe
  const chatbotTitleRef = React.createRef()
  const botBuilderStore = createBotBuilderStore(props)

  const handleChatbotNameChange = async (value: string) => {
    const { campaign } = props

    try {
      await ChatbotService.update(campaign.id, campaign)
      campaign.name = value
    } catch (e) {
      showErrorMessages(e)
    }
  }

  const renderNavbar = () => {
    const { campaign } = props
    const testChatbotUrl = `/chatbots/${campaign.payload}/test?is_open=true`

    return (
      <div className="chatbot-navbar">
        <div className="chatbot-navbar-header-wrapper">
          <h2 className="chatbot-navbar-header-container">
            <CCDynamicInput
              innerClass="chatbot-navbar-header-input"
              text={campaign.name}
              onBlur={handleChatbotNameChange}
              onChange={handleChatbotNameChange}
              enabled
              sanitize
              multiLine={false}
              innerRef={chatbotTitleRef}
            />
            <i
              className="fa fa-pencil chatbot-navbar-header-icon"
              onClick={() => chatbotTitleRef.current && chatbotTitleRef.current.focus()}
            />
          </h2>
          <CCButton color="primary" onClick={() => { window.open(testChatbotUrl, '_blank').focus() }}>
            Test this chatbot
          </CCButton>
        </div>
      </div>
    )
  }

  const renderBotBuilder = () => {
    return (
      <StoreProvider store={store}>
        <Provider {...botBuilderStore}>
          <Subscribe>
            {props => (<BotBuilder {...props}/>)}
          </Subscribe>
        </Provider>
      </StoreProvider>
    )
  }

  return (
    <div className="chatbot-builder">
      {renderNavbar()}
      {renderBotBuilder()}
    </div>
  )
}
