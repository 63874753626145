// @flow

import React from 'react'

import { CCIconButton } from '../../UI'

type Props = {
  subscriber: Object,
  handleDeleteClicked: Function
}

export function SubscribersListRow (props: Props) {
  return (
    <tr key={props.subscriber.id}>
      <td className="font-weight-600">{props.subscriber.name}</td>
      <td className="font-weight-600">{props.subscriber.subscribed_at}</td>
      <td className="font-weight-600">{props.subscriber.source}</td>
      <td className="font-weight-600">{props.subscriber.subscription_state}</td>
      <td className="growth-tools-list__action-column">
        <CCIconButton
          iconName="trash"
          size="mini"
          onClick={() => props.handleDeleteClicked(props.subscriber.id)}
        />
      </td>
    </tr>
  )
}
