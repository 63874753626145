// @flow

import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import { showErrorMessages } from '../../../utils/feedbackMessages'
import { WizardPage, Message, Wizard } from '../../../models'
import { BlockService, MessageService, WizardPagesService } from '../../../services'

import { PageElementList } from './PageElementList/PageElementList'
import { Toolbar } from './Toolbar/Toolbar'
import { Header } from './Header/Header'

import './EditPage.scss'

type Props = {
  page: WizardPage,
  onUpdatePage: Function,
  pages: Array<WizardPage>,
  wizard: Wizard,
  onChangePages: Function
}

export function EditPage (props: Props) {
  const { page, onUpdatePage, pages, wizard, onChangePages } = props
  const [pageElements, setPageElements] = useState([])

  useEffect(() => {
    let isMounted = true
    async function getPageElements () {
      setPageElements([])

      const { messages } = await BlockService.getBlock(page.id)

      if (isMounted) setPageElements(messages.map(message => new Message(message)))
    }

    getPageElements()
    return () => { isMounted = false }
  }, [page])

  function handlePageChanged (param: string, save: boolean = false) {
    return (value: any) => {
      _.set(page, param, value)
      onUpdatePage(page)

      if (save) {
        savePageChanges()
      }
    }
  }

  async function savePageChanges () {
    try {
      await WizardPagesService.update(page.id, page, wizard)
    } catch (e) {
      showErrorMessages(e)
    }
  }

  async function onDeletePageElements (pageElement: Message) {
    try {
      await MessageService.deleteMessage(pageElement.id)
      const elements = pageElements.filter(el => el.id !== pageElement.id)
      setPageElements(elements)
    } catch (e) {
      showErrorMessages(e)
    }
  }

  function handlePageElementCreated (elementType: any, elementParams: Object) {
    return async () => {
      try {
        const message = await MessageService.createMessage({
          block_id: page.id,
          ...(elementType && { message_type: elementType }),
          ...elementParams
        })
        setPageElements([...pageElements, new Message(message)])
      } catch (e) {
        showErrorMessages(e)
      }
    }
  }

  return (
    <div className="wizard-page-edit-wrapper">
      <div className="wizard-page-header-and-elements">
        <Header
          wizard={wizard}
          page={page}
          onChange={handlePageChanged}
          onSave={savePageChanges}
        />
        <div className="wizard-page-elements-wrapper">
          <PageElementList
            page={page}
            pages={pages}
            wizard={wizard}
            pageElements={pageElements}
            onChangePages={onChangePages}
            onChangePageElements={setPageElements}
            onDeletePageElements={onDeletePageElements}
          />
        </div>
      </div>
      <Toolbar
        onClickItem={handlePageElementCreated}
        page={page}
      />
    </div>
  )
}
