// @flow

import React from 'react'

import { CCDropdown, CCIconButton, CCTextInput } from '../../UI'
import type { DropdownOption } from '../../UI/CCDropdown/CCDropdown'

import './EditSubscriberAttributes.scss'

type Props = {
  attributeValue: Object,
  attributeOptions: Object,
  handleChange: Function,
  handleDelete: Function
}

export function SetAttributeValue (props: Props = {}) {
  return (
    <div className="edit-attribute-item__row">
      <div className="edit-attribute-item__row__dropdown">
        <CCDropdown
          autoCompleteEnabled
          truncateAfter={10}
          options={props.attributeOptions}
          value={props.attributeValue.attributeId}
          onChange={(option: DropdownOption) => props.handleChange(props.attributeValue, 'attributeId', option.value)}
          flexible
        />
      </div>
      <div className="edit-attribute-item__row__label">
        =
      </div>
      <div className='edit-attribute-item__row__value-field'>
        <CCTextInput
          placeholder={'Value'}
          onBlur={value => props.handleChange(props.attributeValue, 'value', value)}
          onPressEnterKey={value => props.handleChange(props.attributeValue, 'value', value)}
          value={props.attributeValue.value}/>
      </div>
      <div className='edit-attribute-item__row__remove-button'>
        <CCIconButton
          dataTestid="delete-row"
          iconName="trash"
          size="mini"
          onClick={() => props.handleDelete(props.attributeValue)}
        />
      </div>
    </div>
  )
}
