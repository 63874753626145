// @flow

import React from 'react'

import { KnowledgeItem } from '../../../../models'

import { CCLabel, CCButton } from '../../../UI'

import { ActionsCell } from './columns/ActionsCell'

type CellProps = {
  row: {
    original: KnowledgeItem
  }
}

export const AVAILABLE_COLUMNS = [
  {
    accessor: 'question',
    Header: 'Question',
    Cell: ({ row }: CellProps) => (
      <div>
        <div className='k-table-question'>{row.original.question}</div>
        <div className='k-table-answer'>{row.original.answer}</div>
      </div>
    )
  },
  {
    accessor: 'status',
    Header: 'Status',
    Cell: () => (
      <CCLabel text='Trained' color='success-green' />
    )
  },
  {
    accessor: 'source',
    Header: 'Source',
    Cell: () => (
      <CCButton onClick={() => null} small>
        <i className='fa fa-file-o' />
        .csv file
      </CCButton>
    )
  },
  {
    accessor: 'actions',
    disableSortBy: true,
    Header: '',
    Cell: ActionsCell
  }
]
