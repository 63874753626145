// @flow

import React from 'react'

import { useShop } from '../../../../../hooks'
import { getCurrencies, getLanguages } from '../../../../../utils/localization'

import { CCDropdown } from '../../../../UI'

export function LocalizationPanel () {
  const [shop, propertyChange] = useShop()

  return (
    <div className="settings-item-wrapper">
      <h2 className="settings-header">Localization</h2>
      <h3 className="settings-subheader">Language</h3>
      <p className="settings-text">The language set here will not affect the language of the Chatchamp app but will change user-facing elements of the chatbots and advisors like buttons and default texts.</p>
      <CCDropdown
        customClasses="settings-input"
        options={getLanguages()}
        value={shop.locale}
        onChange={(item) => propertyChange('locale', item.value)}/>
      <h3 className="settings-subheader">Currency</h3>
      <CCDropdown
        options={getCurrencies()}
        value={shop.currency}
        onChange={(item) => propertyChange('currency', item.value)}/>
    </div>
  )
}
