// @flow

import React, { useEffect, useState } from 'react'

import { Wizard, WizardPage } from '../../../models'
import { NewPageModal } from '../NewPageModal/NewPageModal'
import { ItemsByDepth } from './ItemsByDepth'

import './PagesList.scss'

type Props = {
  wizard: Wizard,
  pages: Array<WizardPage>,
  currentPage: WizardPage,
  onClick: Function,
  onDelete: Function
}

export function PagesList ({ pages, currentPage, onClick, wizard, onDelete }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  function buildListByDepth () {
    const list = {}

    pages.forEach((page) => {
      const listDepth = String(page.depth !== null ? page.depth : null)
      const newListItem = {
        id: page.id,
        name: (page.internal_name || page.name),
        isSelected: page.id === currentPage.id,
        depth: listDepth
      }
      if (!list[listDepth]) list[listDepth] = []
      list[listDepth].push(newListItem)
    })

    return list
  }

  const canDeleteItems = (depth: string, isLastDepth: boolean): boolean => {
    if (depth !== 'null') return false

    return !(pages.length === 1 && isLastDepth)
  }

  function renderList () {
    const listByDepth = buildListByDepth()
    const sortedListDepth = Object.keys(listByDepth).sort((a, b) => Number(a) - Number(b))
    const lastListDepth = sortedListDepth.slice(-1)[0]

    return sortedListDepth.map(depth => {
      const isLastDepth = depth === lastListDepth
      const listItems = listByDepth[depth]

      return (
        <ItemsByDepth
          key={depth}
          items={listItems}
          depth={depth}
          isSelected={depth === String(currentPage.depth)}
          isLastDepth={isLastDepth}
          isDeletable={canDeleteItems(depth, isLastDepth)}
          onClickItem={onClick}
          onDeleteItem={onDelete}
        />
      )
    })
  }

  const addNewPage = () => {
    setIsModalOpen(true)
  }

  function onRequestClose (): void {
    setIsModalOpen(false)
  }

  useEffect(() => {
    if (!currentPage.id) addNewPage()
  }, [currentPage.id])

  return (
    <div className="pages-list-wrapper">
      <h1 className="pages-list-header">Pages</h1>
      <p className="pages-list-description">
        Your advisor consists of multiple pages.
        Think of each page as a different question in your conversation.
        Multiple pages can be connected to each other.
      </p>
      <div className="pages-list-items-wrapper">
        <div className="display-flex">
          <div className="pages-list-empty-depth">
            <h2 className="pages-list-subheader-wrapper">Depth</h2>
          </div>
          <div className="pages-list-name-wrapper">
            <h2 className="pages-list-subheader-wrapper pages-list-subheader-margin-left">
              Page Name
            </h2>
          </div>
        </div>
        {renderList()}
        <div className="display-flex">
          <div className="pages-list-empty-depth"/>
          <div
            className="pages-list-item-create"
            onClick={addNewPage}>
            <div className="pages-list-create-text">
              <i className="pages-list-plus-icon fa fa-plus"/>
              ADD NEW PAGE
            </div>
          </div>
        </div>
      </div>
      <NewPageModal wizard={wizard} isOpen={isModalOpen} onRequestClose={onRequestClose}/>
    </div>
  )
}
