// @flow

import React from 'react'
import classnames from 'classnames'

import { useTooltip } from '../../../hooks/useTooltip'

type Props = {
  disabled?: any,
  icon: string,
  id?: string,
  onClick: Function,
  title: string,
  tooltip?: string
}

export function CCToolbarItem ({ icon, title, tooltip, id, disabled, onClick }: Props) {
  useTooltip()

  const classes = classnames('bot-builder-toolbar__item', {
    'bot-builder-toolbar__item--disabled': disabled
  })

  function handleClick (e: any): void {
    if (!disabled) onClick(e)
  }

  return (
    <div id={id} className={classes} onClick={handleClick} data-toggle="tooltip" title={tooltip}>
      <div className="bot-builder-toolbar__item__icon">
        <i className={`icon fa fa-${icon}`}/>
      </div>
      <div className="title">{title}</div>
    </div>
  )
}
